import { ref, remove, update } from 'firebase/database';

export const createName = () => {
//   const prefix = ["Sexy"];
  const prefix = ["Cool", "Super", "Hyper", "Mega", "Ultra"];
  const animals = ["Lion", "Tiger", "Bear", "Shark", "Eagle"];
//   const animals = ["Hawk"];
  return `${randomFromArray(prefix)} ${randomFromArray(animals)}`;
};

export const getRandomSafeSpot = () => {
  const safeSpots = [
    { x: 5, y: 5 },
    { x: 10, y: 10 },
    // { x: 15, y: 15 },
    // { x: 20, y: 20 },
    // Add more safe spots if necessary
  ];
  return randomFromArray(safeSpots);
};

export const randomFromArray = (array) => {
  return array[Math.floor(Math.random() * array.length)];
};

export const playerColors = ['red', 'blue', 'green', 'yellow', 'purple', 'eggplant', 'fox', 'blob', 'knight', 'alien'];

export const isSolid = (x, y) => {
  const solids = [
    { x: 0, y: 0 },
    { x: 1, y: 1 },
    // Define other solid objects if necessary
  ];
  return solids.some(solid => solid.x === x && solid.y === y);
};

export const getKeyString = (x, y) => {
  return `${x}x${y}`;
};

export function attemptGrabCoin(x, y, playerRef, players, playerId, coins) {
  const key = getKeyString(x, y);
  if (coins[key]) {
    // Remove this key from the database, then increase the player's coin count
    const coinRef = ref(playerRef.database, `coins/${key}`);
    remove(coinRef);

    const newCoinCount = players[playerId].coins + 1;
    update(playerRef, {
      coins: newCoinCount,
    });
  }
}

