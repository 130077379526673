import React from 'react';
import Gravatar from 'react-gravatar';
import Avatars from "../avatar.json";

const Avatar = ({ avatar, address, size, minus, active }) => {
  // Find the avatar object based on type
  console.log('active', active)
  const matchedAvatar = Avatars.find(item => item.type === avatar);
  console.log('matchedAvatar ', matchedAvatar)
  return (
  
      <div className={`${minus && 'minus'}`}>
      {/* Render Gravatar based on type */}
      {avatar === "Gravatar" && <Gravatar email={address} className={`avatar-img final-ava ${size}`} />}
      {avatar === "MonsterId" && <Gravatar default="monsterid" email={address} className={`avatar-img final-ava ${size}`} />}
      
      {/* Render image if found in JSON */}
      {matchedAvatar?.image && (
        <img src={matchedAvatar.image} alt={matchedAvatar.name} className={`final-ava ${size}`} />
      )}
    </div>
  );
};

export default Avatar;
