import { useEffect } from 'react';

export const useKeyPress = (targetKey, handler) => {
  useEffect(() => {
    const keyPressHandler = (event) => {
      if (event.key === targetKey) {
        // console.log(`Key pressed: ${targetKey}`); // Log the key press
        handler();
      }
    };

    window.addEventListener('keydown', keyPressHandler);

    return () => {
      window.removeEventListener('keydown', keyPressHandler);
    };
  }, [targetKey, handler]);
};
