import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect, foundersConnect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
import {
  useDialogState,
} from "reakit/Dialog";
import 'react-toastify/dist/ReactToastify.css';
import {
  useDisclosureState,
} from "reakit/Disclosure";
// import Progress from 'react-progress';
import {proofs} from "./proof";
import addressData from "./address.json";
import bonusRewards from '../bonus-rewards.json';
import progress from 'progress';
import { db } from '../firebase';
import NFDButton from "../utils/nfdbutton";
import { doc, updateDoc } from "firebase/firestore";


export default function MinterPage(userId) {

    let docRef = {};
  const dispatch = useDispatch();
  const dialog = useDialogState();
  const disclosure1 = useDisclosureState();
  const disclosure2 = useDisclosureState();
  const disclosure3 = useDisclosureState();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [hasTokens, setHasTokens] = useState(false);
  const [connectingToWallet, setconnectingToWallet] = useState(false);
  const [userErrorMessage, setuserErrorMessage] = useState('');
  const [walletDataVar, setwalletDataVar] = useState({});
  const [mintingStage, setMintingStage] = useState(3); // hardcode the stage for testing here
  const [mintingLimit, setmintingLimit] = useState(null);
  const [somethingPurchased, setSomethingPurchased] = useState(false);
  const [claimingNft, setClaimingNft] = useState(false);
  const [fixedWhiteListMint, setfixedWhiteListMint] = useState(false);
  const [foundersAvailStatus, setfoundersAvailStatus] = useState(null);
  // const [is_minting, setis_minting] = useState(false);
  const [mintPaused, setmintPaused] = useState(false);
  const [selectedMintQuantity, setselectedMintQuantity] = useState(1);
  const [foundersIdtoCheck, setfoundersIdtoCheck] = useState(0);
  const [foundersTotal, setfoundersTotal] = useState(628);
  const [calcingFounderProgress, setcalcingFounderProgress] = useState(false);
  const [totalSupply, settotalSupply] = useState(null);
  const [freeClaimArray, setfreeClaimArray] = useState([]);
  const [ownedTokens, setownedTokens] = useState([]);

  const [errorMessage, seterrorMessage] = useState('');
  const [feedback, setFeedback] = useState('');
  const [mintAmount, setMintAmount] = useState(1);
  const [mintHardAmount, setMintHardAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    FOUNDERS_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    WEI_COST2: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });


  const checkFoundersMintStatus = async () => {
    let ownedClaimableTokens = [];
    setcalcingFounderProgress(true);
    // *** 1 -- Get all founders tokens the wallet owns
    
    for(var i = 0; i < foundersTotal; i++) {
      const owner = await blockchain.foundersContract.methods.ownerOf(i).call();
      if(blockchain.account === owner) {
        // *** 2 -- Check to see if those have been claimed
        const claimedStatus = await blockchain.smartContract.methods._founderMints(i).call(); 
        if (!claimedStatus) {
          // owned and not claimed - add to list
          ownedClaimableTokens.push(i);
        }
      }
    }
    setcalcingFounderProgress(false);
    setfreeClaimArray(ownedClaimableTokens); // set claim amount available 
  }

  const checkTokenFunction = async () => {
    console.log('we checking ', foundersIdtoCheck);
    const claimedStatus = await blockchain.smartContract.methods._founderMints(foundersIdtoCheck).call(); 
    setfoundersAvailStatus(claimedStatus);
  }

  const foundersClaimNFTs = () => {    
    // *** 3 --- set mint amount to whats left and pass to minting function
    const cost = 0;
    const gasLimit = CONFIG.GAS_LIMIT;
    const totalGasLimit = String(gasLimit * freeClaimArray.length);

    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods.claim(freeClaimArray)
    .send({
      gasLimit: String(totalGasLimit),
      from: blockchain.account,
      value: cost,
    })
    .once("error", (err) => {
      console.log(err);
      setFeedback("Sorry, something went wrong please try again later.");
      seterrorMessage("Sorry, something went wrong please try again later.");
      setClaimingNft(false);
    })
    .then((receipt) => {
      console.log(receipt);
      setFeedback(
        `You have minted your NFT successfully! Go visit Opensea.io to view it.`
      );      
      setClaimingNft(false);
      setSomethingPurchased(true);
      dispatch(fetchData(blockchain.account));
    });
  };



  const claimNFTs = () => {

    // check to see what stage      
    const cost = CONFIG.WEI_COST;
    let totalCostWei = String(cost * selectedMintQuantity);
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * mintAmount);
    let addressProof = proofs[walletDataVar.index]

    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    // document.getElementsByTagName("body")[0].style = "overflow: hidden";
    blockchain.smartContract.methods.whitelistMint(selectedMintQuantity, addressProof)
      .send({
        gasLimit: String(totalGasLimit),
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        seterrorMessage("Sorry, error minting. Please double check quantity.");
        setClaimingNft(false);
        // document.getElementsByTagName("body")[0].style = "overflow: visible";
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `You have minted your NFT successfully! Go visit Opensea.io to view it.`
        );
        
        setClaimingNft(false);
        // document.getElementsByTagName("body")[0].style = "overflow: visible";
        setSomethingPurchased(true);
        dispatch(fetchData(blockchain.account));
      });
  };

  const openMint = async () => {

    // check to see what stage
    const cost = CONFIG.WEI_COST;
    let totalCostWei = String(cost * selectedMintQuantity);
    // let gasLimit = CONFIG.GAS_LIMIT;
    // let totalGasLimit = String(gasLimit * mintAmount);

    const gasAmount = await blockchain.smartContract.methods.purchase(selectedMintQuantity).estimateGas({
      to: CONFIG.CONTRACT_ADDRESS,    
      from: blockchain.account,
      value: totalCostWei
    })
    .catch(function (error) {
      if (error.message.includes("Exceeds wallet limit")) {
        seterrorMessage("This transaction would take you over your wallet limit.");
      } 
      else {
        seterrorMessage("Insufficient funds for gas. Please add more ETH to your wallet.");
      }
    });
    
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    // document.getElementsByTagName("body")[0].style = "overflow: hidden";
    blockchain.smartContract.methods.purchase(selectedMintQuantity)
      .send({
        gasLimit: String(gasAmount),
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        seterrorMessage("Sorry, error minting.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `You have minted your NFT successfully! Go visit Opensea.io to view it.`
        );
        
        setClaimingNft(false);
        setSomethingPurchased(true);
        dispatch(fetchData(blockchain.account));
      });
  };

  const getContractStatus = () => {
    console.log('getting contract info')
    // blockchain.smartContract.methods._foundersTokens().call().then((qnty) => {
    //   // console.log('qty ', qty);
    //   // setfoundersTotal(qnty);
    // });
    blockchain.smartContract.methods.totalSupply().call().then((supply) => {
      settotalSupply(supply);
    });

    blockchain.smartContract.methods.tokensOfOwner(blockchain.account).call().then((ownedTokens) => {
        console.log('we own ', ownedTokens);
        setownedTokens(ownedTokens);
        saveWalletInfo(blockchain.account, ownedTokens);
        // settotalSupply(supply);
      });
    
      
    // blockchain.smartContract.methods._whitelistWalletLimit().call().then((limit) => {
    //   setmintingLimit(limit);
    // });
    // blockchain.smartContract.methods._whitelistFixed().call().then((bool) => {
    //   setfixedWhiteListMint(bool);
    // });
    // blockchain.smartContract.methods._status().call().then((stage) => {
    //   setMintingStage(stage);

    // });
    
  } 
  const changeCondition = () => {
    setconnectingToWallet(true);
  }

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
        console.log('blockchain.account ', blockchain.account);
      dispatch(fetchData(blockchain.account));
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setselectedMintQuantity(value);
  }

  
  const handleTokenChange = (e) => {
    const value = e.target.value;
    setfoundersIdtoCheck(value);
  }

  const handleWalletConnect = (e) => {
    console.log('here')
    e.preventDefault();
    dispatch(connect());
    getData();
    changeCondition();
  }

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
    let tempVar = {};
    // lets stateify the error mssg
    if (blockchain.errorMsg !== null || blockchain.errorMsg !== '') {
      seterrorMessage(blockchain.errorMsg);
    }
    if (blockchain.account !== "" && blockchain.account !== null) {
      getContractStatus();
      setconnectingToWallet(false);
      tempVar = addressData.find(item => item.address.toLowerCase() == blockchain?.account?.toLowerCase());
    }
    console.log('tempVar ', tempVar);
    setwalletDataVar(tempVar);
    // saveWalletInfo(blockchain.account, )
  }, [blockchain]);

  const saveWalletInfo = async (address, items) => {
    // check for bonus rewards.
    const walletConnectReward = {
        "id": 200,
        "name": "Web3 Guru",
        "total": 0,
        "image": "wallet",
        "category": "wallet",
        "pointTotal": 25,
        "criteria": null,
        "daysExpire": null,
        "reward": "You are connected! Check the leaderboard!"
    }
    let holderRewardsEarned = [];
    let pointsEarned = 0;
    if(items.length > 0) {
        console.log('we have a holder', address);
        bonusRewards.forEach((holderReward) => {
           if (items.length >= holderReward.criteria) {
            holderRewardsEarned.push(holderReward);
            pointsEarned = pointsEarned + holderReward.pointTotal;
           }
        });
        pointsEarned = pointsEarned + walletConnectReward.pointTotal;
        holderRewardsEarned.push(walletConnectReward);
    }
    console.log('address', address);
    console.log('userId.totalPointsPass ', userId.totalPointsPass);
    const formingTotalPoints = userId.totalPointsPass + pointsEarned;
    docRef = doc(db, "purchasedTokens", userId.userId);
    await updateDoc(docRef, {
      tokensOwned: items,
      walletAddress: address,
      holderRewards: holderRewardsEarned,
      holderPoints: pointsEarned,
      totalPoints: formingTotalPoints
    })
    .then((result) => {
        console.log('sucksess', result);
        userId.dataCallback(holderRewardsEarned);
    })
  };

  return (
    <>
  <div className="div-block-24">
  {ownedTokens.length > 0 &&
  <h1 className="mint-box-heading font-adj">NFD Holder </h1>}

    {ownedTokens.length > 0 &&
        ownedTokens?.map((c, i) => (
            <h1 className="mint-box-heading font-adj" key={i+c}>#{c} </h1>
        ))
    }
    {blockchain.account !== null && !somethingPurchased && 
     // wallet_address && !minted
      <button
        className="clicklink bluebut openseabdg widthsev green"
        >
        <div className="container2">
          <div className="farf">
            <i className="fa fa-check fa-lg"></i>
            <span className="label labelsh" >Wallet Connected</span>
          </div>
        </div>
      </button>
    }
    {somethingPurchased && 
      <button
        className="btn-primary active wallc suc"
        id="minted"
      >
        <div className="container2">
          <div className="farf">
            <i className="fa fa-check fa-lg"></i>
            <span className="label labelsh" >Mint Successful!</span>
          </div>
        </div>
      </button>
    }
    
    {blockchain.account === "" || blockchain.account === null ?
      <>
        { !connectingToWallet ?
          <>
            {/* <p className="paragraph-6">
              Click the “MINT NOW” button to start the minting proces. You’ll be asked to connect your wallet where we’ll check to see if you are on the allowlist and how many tokens you can mint.
              <br /><span className="paragraph-66">SUPPLY:<strong>{CONFIG.MAX_SUPPLY}</strong></span> <br />
              <span className="paragraph-66">PRICE: <strong>{CONFIG.DISPLAY_COST}ETH</strong></span>
              
            </p> */}
           
          </>
          :
          <>
            
            <>
              <p className="paragraph-6" id="connecting_wallet">To mint, you must connect your wallet to our site. Follow the prompts given by your wallet.</p>
              <div id="connecting_wallet" className="mt-3">
                <p>Waiting for wallet connection...</p>
                <progress className="progress is-small is-primary mt-3" max="100">
                  15%
                </progress>
              </div>
              </>
            
          </>
        }        
      </> 
      : 
      <>
        {calcingFounderProgress  && 
          <div id="connecting_wallet" className="mt-3">
            <p>Loading...</p>
            <progress className="progress is-small is-primary mt-3" max="100">
              15%
            </progress>
          </div>
        }
         
        {/* stage 1 process goes here -- Allowlist allocation */}
        {/* {mintingStage == 1 &&
          <>
            {walletDataVar !== undefined && !claimingNft && !somethingPurchased &&
              // wallet_address && !is_minting && !minted
              <div className="paragraph-6">
                <p className="paragraph-46">Congrats! You're on the allowlist! You're eligible to mint <strong>{ fixedWhiteListMint ? mintingLimit : ' unlimited'}</strong> Genesis tokens.</p>
                <p className="paragraph-66">PRICE: <strong>0.088ETH</strong></p>
              </div>
            }
            
            { walletDataVar !== undefined && claimingNft && !somethingPurchased &&
              // is_minting && !minted && !has_failed
              <p className="paragraph-6">We are currently waiting for the blockchain to confirm your transaction.</p>
            }
            {somethingPurchased && 
            // minted
              <p className="paragraph-6">Congratulations! You've successfully minted your Genesis Token(s)!</p>
            }
            { walletDataVar == null && 
              <p className="paragraph-6">Sorry you are not on the allowlist for the Genesis token</p>
            }
          </>
        } */}

        {/* stage 2 process goes here -- Founders allocation */}
        {/* {mintingStage == 2 && freeClaimArray.length > 0 &&
          <>
            {!claimingNft && !somethingPurchased &&
              // wallet_address && !is_minting && !minted
              <div className="paragraph-6">
                <p className="paragraph-46">Congrats! As a Founders token holder, you're eligible to mint <strong>{freeClaimArray.length}</strong> Genesis tokens.</p>
                <p className="paragraph-66">PRICE: <strong>0.0ETH (gas only)</strong></p>
              </div>
            }
            
            {claimingNft && !somethingPurchased &&
              // is_minting && !minted && !has_failed
              <p className="paragraph-6">We are currently waiting for the blockchain to confirm your transaction.</p>
            }
            {somethingPurchased && 
            // minted
              <p className="paragraph-6">Congratulations! You've successfully minted your Genesis Token(s)!</p>
            }
          </>
        } */}

        {/* {mintingStage == 2 && freeClaimArray.length == 0 && !calcingFounderProgress &&
          <p className="paragraph-6">Sorry, you do not own any unclaimed Founders tokens. Please wait for the Open Mint stage.</p>
        } */}

         {/* stage 3 process goes here  -- open mint  */}
         {/* HAWK HAWK HAWK THIS IS OPEN MINUT */}
         {/* {mintingStage == 3 &&
          <>
            {!claimingNft && !somethingPurchased &&
              // wallet_address && !is_minting && !minted
              <div className="paragraph-6">
                <p className="paragraph-46">Click the “MINT NOW” button to mint your NFDaddy.</p>
                <p className="paragraph-66">PRICE: <strong>0.07ETH</strong></p>
              </div>
            }
            
            {claimingNft && !somethingPurchased &&
              // is_minting && !minted && !has_failed
              <p className="paragraph-6">We are currently waiting for the blockchain to confirm your transaction.</p>
            }
            {somethingPurchased && 
            // minted
              <p className="paragraph-6">Congratulations! You've successfully minted your Genesis Token(s)!</p>
            }
          </>
        } */}
        
        </>
    }
    
    <div className="mint-button overbu">
    {userId.walletStatus && 
        <div className="white">Wallet Verified! <a href="" className="relink">Re-connect.</a></div>
    }
      {blockchain.account === "" || blockchain.account === null && !userId.walletStatus ? 
        // !minted && !wallet_address && !connecting_wallet
       !connectingToWallet && !somethingPurchased &&
        <div id="minterbtn">
          <NFDButton label="CONNECT" icon="wallet" sublabel="your wallet" color="blue-btn" handleClick={handleWalletConnect}
          />
        </div>
      :
        <div>
        {/* { errorMessage !== '' && 
          <div>
            <p className="mt-3">Please connect to the correct network</p>
          </div>
        } */}
        { mintPaused && 
          <div>
            <p className="mt-3">MINT NOT STARTED</p>
          </div>
        }
        
        {Number(totalSupply) >= CONFIG.MAX_SUPPLY && 
          <div>
            <p>SOLD OUT</p>
            <a
              href="https://opensea.io/collection/nerdcollective-genesis-pfp"
              target="_blank"
              className="mt-1 button is-link"
            >
              Buy on OpenSea
            </a>
        </div>
        }
        
        <div>
          {/* {mintingStage == 1 && walletDataVar !== undefined && !claimingNft && !somethingPurchased &&
            // !is_minting && !minted && !has_failed
            <div className="mt-3">
            <div className="field is-grouped mt-3 fully-center">
              <p className="control is-expanded">
              <input
                  className="input"
                  type="number"
                  placeholder="How many do you want?"
                  max={fixedWhiteListMint ? mintingLimit : undefined }
                  min="0"
                  value={selectedMintQuantity}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </p>
              <p className="control">
                <button className="btn-primary" onClick={ (e) => claimNFTs()}>
                  <div className="container">
                    <span className="label">MINT NOW</span>
                  </div>
                </button>
              </p>
            </div>
          </div>
          } */}

          {/* {mintingStage == 2 && freeClaimArray.length > 0 && !claimingNft && !somethingPurchased &&
            // !is_minting && !minted && !has_failed
            <div className="mt-3">
            <div className="field is-grouped mt-3 fully-center">
              <p className="control">
                <button className="btn-primary" onClick={ (e) => foundersClaimNFTs()}>
                  <div className="container">
                    <span className="label">CLAIM NOW</span>
                  </div>
                </button>
              </p>
            </div>
          </div>
          } */}

          {blockchain.account !== null && !claimingNft && !somethingPurchased &&
            // !is_minting && !minted && !has_failed
            // HAWK HAWK HAWK THIS IS OPEN MINT
            <div className="mt-3">
            <div className="field is-grouped mt-3 fully-center">
              <p className="control is-expanded">
              <input
                  className="input"
                  type="number"
                  placeholder="How many do you want?"
                  min="1"
                  value={selectedMintQuantity}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </p>
              <p className="control">
                <button className="btn-primary" onClick={ (e) => openMint()}>
                  <div className="container">
                    <span className="label">MINT NOW</span>
                  </div>
                </button>
              </p>
            </div>
          </div>
          }     
          
          { claimingNft && !somethingPurchased && 
            // is_minting && !minted && !has_failed
            <div  className="mt-3">
              <p>Minting...</p>
              <progress className="progress is-small is-primary mt-3" max="100">
                  15%
              </progress>
            </div>
          }
          
          { claimingNft && !somethingPurchased && walletDataVar !== null && walletDataVar !== undefined &&

            <div
              className="notification is-link mt-4"
              >
              Transaction created :
              <a
                target="_blank"
                href="https://etherscan.io/address/0xeafde71d84cbad86323030ea1799c04103deac25"
                >View on Etherscan</a>
            </div>
          
          }
          
          {mintingStage == 0 && 
            <p className="paragraph-6">Sorry, minting for the Genesis PFP is currently paused.</p>
          }
          
          { somethingPurchased && 
            <div id="minted">
              <a
                className="btn-primary mt-2"
                href="https://opensea.io/collection/nerdcollective-genesis-pfp"
                target="_blank"
              >
                <div className="container">
                  <span className="label">VIEW TOKENS ON OPENSEA</span>
                </div>
              </a>
            </div>
          }          
        </div>
        </div>
      }
      {/* // transaction_hash == null && !minted && !connecting_wallet */}
    {/* <div className="buttonSubText" >By clicking “mint now” you agree to our <a href="https://www.nerdcollective.xyz/terms-conditions" target="_blank">terms and conditions.</a></div> */}
    { errorMessage && <div className="notification mt-5 rederror"> {errorMessage}</div>}
  </div>
</div>
      
{blockchain.account !== null && mintingStage == 2 && 
  <div className="checkholder">
    <span className="checklabel">Check to see if Founders Token #ID has been claimed.</span>
    <div className="input-conat">
      <input
        className="input"
        type="number"
        placeholder="Founders Token ID"
        min="0"
        max="627"
        value={foundersIdtoCheck}
        onChange={(e) => {
          handleTokenChange(e);
        }}
      />
      <button 
        disabled={foundersIdtoCheck == undefined || foundersIdtoCheck == null  ? true : false}
        onClick={() => {
          checkTokenFunction();
        }}>Check Token
      </button>
    </div>
    { foundersAvailStatus !== null &&
    <div className="statsbox">
      <span className="statslabel">Status: </span>
      
      { foundersAvailStatus !== null && foundersAvailStatus === true &&
        <span  className="yes">Already Claimed</span>
      }
      { foundersAvailStatus !== null && foundersAvailStatus === false &&
        <span  className="no">Available</span>
      }
    </div>
    }
    </div>
}
</>
)
};

