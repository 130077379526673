import React, { useEffect, useState, useRef } from 'react';
import { ref as dbRef, set, update, onValue, onChildAdded, onChildRemoved, onDisconnect } from 'firebase/database';
import { auth, database } from './firebase';
import { createName, getRandomSafeSpot, playerColors } from './components/helpers';
import { useKeyPress } from './components/KeyPressListener';
import { onAuthStateChanged, signInAnonymously } from 'firebase/auth';
import tagged from "./assets/images/tagged_1.png";
import "./game.css";

const Game = () => {
  const [playerId, setPlayerId] = useState(null);
  const [players, setPlayers] = useState({});
  const playerNameRef = useRef(null);
  const playerRef = useRef(null);

  const mapData = {
    minX: 1,
    maxX: 14,
    minY: 4,
    maxY: 13,
    blockedSpaces: {
      "7x4": true,
      "1x11": true,
      "12x10": true,
      "4x7": true,
      "5x7": true,
      "6x7": true,
      "8x6": true,
      "9x6": true,
      "10x6": true,
      "7x9": true,
      "8x9": true,
      "9x9": true,
    },
  };

  useKeyPress('ArrowUp', () => handleArrowPress(0, -1));
  useKeyPress('ArrowDown', () => handleArrowPress(0, 1));
  useKeyPress('ArrowLeft', () => handleArrowPress(-1, 0));
  useKeyPress('ArrowRight', () => handleArrowPress(1, 0));

  useEffect(() => {
    const initGame = () => {
      const allPlayersRef = dbRef(database, 'players');

      const playerListener = onValue(allPlayersRef, (snapshot) => {
        const data = snapshot.val() || {};
        setPlayers(data);

        Object.keys(data).forEach((playerId) => {
          const playerData = data[playerId];
          const characterElement = document.querySelector(`[data-id="${playerId}"]`);
          if (characterElement) {
            const left = 16 * playerData.x + "px";
            const top = 16 * playerData.y - 4 + "px";
            characterElement.style.transform = `translate3d(${left}, ${top}, 0)`;
            characterElement.setAttribute("data-direction", playerData.direction);
            characterElement.classList.toggle("it", playerData.isIt);
          }
        });
      });

      const playerAddedListener = onChildAdded(allPlayersRef, (snapshot) => {
        const addedPlayer = snapshot.val();
        const characterElement = document.createElement("div");
        characterElement.classList.add("Character", "grid-cell");
        characterElement.setAttribute("data-id", addedPlayer.id);
        characterElement.classList.toggle("it", addedPlayer.isIt);
        characterElement.setAttribute("data-color", addedPlayer.color);
        characterElement.innerHTML = (`
          <div class="Character_shadow grid-cell"></div>
          <div class="Character_sprite grid-cell"></div>
          <div class="Character_name-container">
            <span class="Character_name">${addedPlayer.name}</span>
          </div>
          <div class="Character_you-arrow"></div>
        `);

        const left = 16 * addedPlayer.x + "px";
        const top = 16 * addedPlayer.y - 4 + "px";
        characterElement.style.transform = `translate3d(${left}, ${top}, 0)`;

        const gameContainer = document.querySelector(".game-container");
        if (gameContainer) {
          gameContainer.appendChild(characterElement);
        } else {
          console.error('Game container not found.');
        }
      });

      const playerRemovedListener = onChildRemoved(allPlayersRef, (snapshot) => {
        const removedPlayerId = snapshot.val().id;
        const gameContainer = document.querySelector(".game-container");
        if (gameContainer) {
          const playerElement = gameContainer.querySelector(`[data-id="${removedPlayerId}"]`);
          if (playerElement) {
            gameContainer.removeChild(playerElement);
          }
        }

        // Reassign "it" status if the removed player was "it"
        const removedPlayer = players[removedPlayerId];
        if (removedPlayer && removedPlayer.isIt) {
          const remainingPlayers = Object.keys(players).filter(id => id !== removedPlayerId);
          if (remainingPlayers.length > 0) {
            const nextPlayerId = remainingPlayers[Math.floor(Math.random() * remainingPlayers.length)];
            update(dbRef(database, `players/${nextPlayerId}`), { isIt: true });
          }
        }
      });

      return () => {
        playerListener();
        playerAddedListener();
        playerRemovedListener();
        if (playerRef.current) {
          onDisconnect(playerRef.current).cancel();
        }
      };
    };

    const authSubscription = onAuthStateChanged(auth, (user) => {
      if (user) {
        const id = user.uid;
        setPlayerId(id);
        playerRef.current = dbRef(database, `players/${id}`);

        const name = createName();
        if (playerNameRef.current) {
          playerNameRef.current.value = name;
        }

        const { x, y } = getRandomSafeSpot();

        onValue(dbRef(database, 'players'), (snapshot) => {
          const playersData = snapshot.val() || {};
          const hasIt = Object.values(playersData).some(player => player.isIt);

          set(playerRef.current, {
            id,
            name,
            direction: "right",
            color: playerColors[Math.floor(Math.random() * playerColors.length)],
            x,
            y,
            isIt: !hasIt,
          }).then(() => {
            initGame();
          });

          onDisconnect(playerRef.current).remove();
        }, { onlyOnce: true });
      }
    });

    signInAnonymously(auth).catch((error) => {
      console.error('Error signing in anonymously:', error.code, error.message);
    });

    return () => {
      authSubscription();
    };
  }, []);

  const getKeyString = (x, y) => `${x}x${y}`;

  const showTaggedMessage = () => {
    const message = document.getElementById('tagged-message');
    message.classList.remove('hidden');
    
    requestAnimationFrame(() => {
      message.style.opacity = 1;
    });

    setTimeout(() => {
      message.style.opacity = 0;

      setTimeout(() => {
        message.classList.add('hidden');
      }, 500);
    }, 2000);
  };

  const isSolid = (x, y) => {
    const blockedNextSpace = mapData.blockedSpaces[getKeyString(x, y)];
    return (
      blockedNextSpace ||
      x >= mapData.maxX ||
      x < mapData.minX ||
      y >= mapData.maxY ||
      y < mapData.minY
    );
  };

  const handleArrowPress = (dx, dy) => {
    if (!playerId) return;
    const player = players[playerId];
    if (!player) return;
    const newX = player.x + dx;
    const newY = player.y + dy;
    let dir = "right";
    if (dx === -1) {
        dir = "left";
    }

    if (!isSolid(newX, newY)) {
      update(playerRef.current, { x: newX, y: newY, direction: dir }).then(() => {
        if (player.isIt) {
          Object.keys(players).forEach((otherPlayerId) => {
            const otherPlayer = players[otherPlayerId];
            if (otherPlayerId !== playerId && otherPlayer.x === newX && otherPlayer.y === newY) {
              update(dbRef(database, `players/${otherPlayerId}`), { isIt: true }).then(() => {
                update(playerRef.current, { isIt: false });

                // Set an alert for all players to trigger the message display
                Object.keys(players).forEach((playerId) => {
                  update(dbRef(database, `players/${playerId}`), { alert: 'A player was tagged!' });
                });
              });
            }
          });
        }
      });
    }
  };

  useEffect(() => {
    const allPlayersRef = dbRef(database, 'players');
    onValue(allPlayersRef, (snapshot) => {
      const playersData = snapshot.val() || {};

      Object.keys(playersData).forEach((playerId) => {
        if (playersData[playerId].alert && playerId === auth.currentUser.uid) {
          showTaggedMessage();
          update(dbRef(database, `players/${playerId}`), { alert: null });
        }
      });
    });
  }, []);

  return (
    <>
    <div className='main-game'>

      <div className="game-container"></div>
      <div id="tagged-message" className="hidden">
        <img src={tagged} />
      </div>
    </div>
    </>
  );
};

export default Game;
