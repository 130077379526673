import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NFDButton = (props) => {
    console.log('props ', props);
  return (
    <>
       <button
            className={`daddybutton ${props.color}`}
            // onClick={() => {props.handleClick()}}
        >
            <div>
                <FontAwesomeIcon icon={props.icon} className="btn-icon" />
            </div>
            <div className="text-btn-ctn">
                <h3 className="but-lab">{props.label}</h3>
                <span className="sublabel-btn">{props.sublabel}</span>
            </div>
        </button>
    </>
  );
};

export default NFDButton;
