// JavaScript
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getAuth, signInAnonymously } from 'firebase/auth';
import { getDatabase, ref as dbRef, set, update, remove, onValue, onChildAdded, onChildRemoved } from 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyDuPCv6vu_FZWaxphkH2Sx1KmAZOBNdLU8",
  authDomain: "lpm-rewards.firebaseapp.com",
  projectId: "lpm-rewards",
  storageBucket: "lpm-rewards.firebasestorage.app",
  messagingSenderId: "539928902747",
  appId: "1:539928902747:web:c3a9c9aa3de82eaf04137e",
  measurementId: "G-ZEB33D02FN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get Firestore, Auth, Storage, and Realtime Database instances
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);
const database = getDatabase(app);

export { db, storage, auth, database, storageRef, uploadBytes, signInAnonymously, getDownloadURL, dbRef, set, update, remove, onValue, onChildAdded, onChildRemoved };
