import React, { useEffect, useState } from "react";
// import logo from "../assets/logo.svg";
import { db } from '../firebase';
import { collection, onSnapshot } from "firebase/firestore";
import moment from 'moment';
import { NavLink as RouterNavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Button } from "reakit/Button";
import {
  useDialogState,
  Dialog,
  DialogBackdrop,
  DialogDisclosure,
} from "reakit/Dialog";
import Loading from "../components/Loading";
import starslogo from "../assets/stars.png";

export const Partners = () => {

  // const [checkinStatus, setCheckinStatus] = useState(false);
  const [fullPartnerList, setFullPartnerList] = useState([]);
  const [adminAllowed, setadminAllowed] = useState(false);
  const [productList, setProductList] = useState([]);
  const [currentOrder, setcurrentOrder] = useState([]);
  const [currentUser, securrentUser] = useState({});
  const [selectedPartner, setSelectedPartner] = useState({});
  const dialog = useDialogState();
  const dialogPartner = useDialogState();
  const { user } = useAuth0();
  const [query, setQuery] = useState("");
  
  // let fullPartnerList = [];
  // const [tempUserDataCheckins, settempUserDataCheckins] = useState([]);
  // let docRef = {};
  // let userWalletInfo = {};
  // const nowTime = moment().format("MMM Do YYYY");
  // const currentMonth = moment(new Date()).format('MMMM');

  useEffect(() => {
    const tempProductList = [
      { id: 0, name: 'Cupcake', total: 0, img:'cupcake', category: 'baked-good'},
      { id: 1, name: 'Milkshake', total: 0, img:'cupcake', category: 'drink'},
      { id: 2, name: 'Cookie', total: 0, img:'cupcake', category: 'baked-good'},
      { id: 3, name: 'Cake Jar', total: 0, img:'donut', category: 'baked-good'},
      { id: 3, name: 'Cookie Dough Jar', total: 0, img:'donut', category: 'baked-good'},
      { id: 4, name: 'Extra Points', total: 0, img:'donut', category: 'baked-good'}
    ]
    setProductList(tempProductList);
    // gettingUserInfoFromFullList();
    
    // let tempUserData = []

    const colRef = collection(db, "partners")
    //real time update
    onSnapshot(colRef, (snapshot) => {
      let tempTokenHolderArray = [];
      snapshot.docs.forEach((doc) => {
        let totes = 0;
        let item = doc.data();
        console.log('teim, ', item);
        item.id = doc.id;
        // item.formedBio = item.bio.slice(0, 125);
        item.formedBio =  item.bio.substring(0, 120) + "..."
        tempTokenHolderArray.push(item);
      })
      tempTokenHolderArray.sort((a,b) => (b.name < a.name) ? 1 : ((a.name < b.name) ? -1 : 0));
      console.log('tempTokenHolderArray ', tempTokenHolderArray);
      setFullPartnerList(tempTokenHolderArray);
    })
    
    return () => {};
  }, []);

  const gettingUserInfoFromFullList = async () => {
    let tempTokenHolderArray = [];
    // let tempUserData = []

    const colRef = collection(db, "purchasedTokens")
    //real time update
    onSnapshot(colRef, (snapshot) => {
      snapshot.docs.forEach((doc) => {
        let totes = 0;
        let item = doc.data();
        console.log('teim, ', item);
        item.id = doc.id;
        const formedDate = Date(item.created);

        const emailHolder = item.emailAddress.toLowerCase();
        const userEmail = user.email.toLowerCase();
       
        if (emailHolder === userEmail) {
          console.log('hey here is you', user, ' - ', item);
          // this is who wants to view this page..
          // check if they are admin
          if(item.admin) {
            setadminAllowed(true);
          }
        }
        item.userCreated = moment(formedDate).format("MMM YYYY");
        if (item.metaData) {
          totes = item.metaData.totalCheckin;
          // console.log('item.metaData.totalCheckin totes', totes);
          if (totes > 0 && item.status === 'checkedin') {
            tempTokenHolderArray.push(item);
          }
        }
        
      })
      tempTokenHolderArray.sort((a,b) => (a.metaData.totalCheckin < b.metaData.totalCheckin) ? 1 : ((b.metaData.totalCheckin < a.metaData.totalCheckin) ? -1 : 0));
      console.log('tempTokenHolderArray ', tempTokenHolderArray);
      setFullPartnerList(tempTokenHolderArray);
    })
  }; 

  const saveUsersPurchase = () => {
    dialog.hide();
  }

  const cancelSave = () => {
    dialog.hide();
  }
  
  const addProdtoOrder = (item) => {
    let builCurrentOrder = currentOrder;
    // builCurrentOrder[item.name] = {};
    let iteming = {
      name: item.name,
      qty: 1
    }
    if (builCurrentOrder[item.name]) {
      // excists
      // add qty
      iteming.qty ++;
      builCurrentOrder[item.name] = iteming;
      // replace shit
    } else {
      builCurrentOrder[item.name] = iteming;
    }
    setcurrentOrder(builCurrentOrder)
    console.log('builCurrentOrder ', builCurrentOrder);
    console.log('builCurrentOrder ', currentOrder);
  }
  // const selectLobbyUser = (selectedUser) => {
  //   console.log('we got a user', selectedUser);
  //   const getId = selectedUser.metaData.id;
  //   // selectedUser.lastVist = moment(numberGet).format("MMM Do YYYY");
  //   // console.log('numberGet', numberGet);
  //   // securrentUser(selectedUser);
  // }

  const addProduct = (prod) => {
    console.log('up ', prod);
    let tempproductList = productList;
    // loop through product array..
    tempproductList.forEach((doc, i) => {
       // if out a match for prod
      if (doc.name === prod.name) {
        // prod.value = prod.value++;
        let currentTotal = prod.total;
        console.log('we have a mrch ', prod);
        tempproductList[i].total = currentTotal + 1;
      }
    });
    // fullProductList = tempproductList;
    // console.log('fullProductList ', fullProductList);
    // fullProductList = fullProductList.slice(1);
    setProductList(tempproductList);
  }

  

  return (
    <div className="text-center hero my-5 leadery">
    {/* <img className="mb-3 app-logo" src={logo} alt="React logo" width="120" /> */}
      <h1 className="mb-4">Partners</h1>
      <div className="inputholder">
          <input className="searchfield" placeholder="Search" onChange={event => setQuery(event.target.value)} />
      </div>
      <div className="leader-contain">
      {fullPartnerList.filter(j => {
            if (query === '') {
                return j;
            } else if (j.name.toLowerCase().includes(query.toLowerCase())) {
             return j;
            }
        }).map((d, i) => (
          <DialogDisclosure key={i+d.name} className="open-dia" {...dialogPartner}  onClick={() => setSelectedPartner(d)}>
            <div className="container-lead open-dia diachange" >
              {/* <div className={i <= 2 ? 'active-leader-item' : 'leader-item'}> */}
              <div className="leader-item btnlead fpitem">
              
                <div className="leader-name morestuff">
                  <div className="stuff">
                    { d.name === 'The Stars Podcast' ? 
                    <img src={starslogo} alt={d.name} className="partnerimage"/>
                    :
                    <img src={d.image} alt={d.name} className="partnerimage"/>
                  }
                    <span>{d.name}</span>
                    </div>
                  <div className="membersince newbio">
                    <span>{d.formedBio}</span>
                    <span className="partlink">View more.</span>
                  </div>
                </div>
              </div>
            </div>
            </DialogDisclosure>
        ))}
        { fullPartnerList?.length < 1 &&
            <p className="padtopst">No users currently checked in!</p>
          }
      </div>
      <DialogBackdrop {...dialogPartner}  className="backstyle">
        <Dialog className="refix defix" {...dialogPartner} aria-label="Welcome">
          <h3 className="claimh dclaimg">{selectedPartner.name}</h3>
          <Button className="closebtn" onClick={dialogPartner.hide}><FontAwesomeIcon icon="times" className="mr-2" /></Button>
          <div className="flutarsky">
            <div className="childtarsky">
              { selectedPartner.name === 'The Stars Podcast' ? 
                <img src={starslogo} alt={selectedPartner.name} className="fartnerimage"/>
                :
                <img src={selectedPartner.image} alt={selectedPartner.name} className="fartnerimage"/>
              }
              <div className="bigbio">{selectedPartner.bio}</div>
              <div className="linksholder">
                  <a className="pjlinks" href={`https://opensea.io/collection/${selectedPartner.opensea}`} target="_blank">
                    <FontAwesomeIcon icon="link" className="gold ico stee ve" /> 
                      <span>Opensea</span>
                  </a>
                  <a className="pjlinks" href={selectedPartner.website} target="_blank">
                    <FontAwesomeIcon icon="link" className="gold ico stee ve" /> 
                    <span>Website</span>
                  </a>
                  <a className="pjlinks" href={'https://twitter.com/'+selectedPartner.twitter} target="_blank">
                    <FontAwesomeIcon icon="link" className="gold ico stee ve" /> 
                    <span>Twitter</span>
                  </a>
                </div>
                <div className="claimpart">
                <RouterNavLink
                    to={"#"}
                    className="hrefcheck"
                    activeClassName="router-link-exact-active"
                  >
                    <button className="clicklink bluebut openseabdg" >Claim Holder Rewards!</button>
                    
                  </RouterNavLink>
                  
              </div>
            </div>
            
          </div>
        
        </Dialog>
      </DialogBackdrop>
    
  </div>
  
  );
};


export default withAuthenticationRequired(Partners, {
  onRedirecting: () => <Loading />,
});
// export default Partners;
