import React from "react";

const Footer = () => (
  <footer className="bg-light p-3 text-center">
    {/* <div className="logo" /> */}
    <p>
    {/* Copyright 2022 NFDaddys.<br /> */}
    {/* <span>Powered by <a href="https://www.nfdaddys.com/" rel="noopener noreferrer" target="_blank">NFD</a>.</span><br /> */}
    <span className="fonsmal"><a href="#" rel="noopener noreferrer" target="_blank">Privacy Policy</a></span>
    <span className="seper">|</span>
    <span className="fonsmal"><a href="#" rel="noopener noreferrer" target="_blank">Terms of Use</a></span>
    </p>
  </footer>
);

export default Footer;
