// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  INFURA_ADDRESS: 'https://mainnet.infura.io/v3/64089c2e1bef4032a89f71267d4dd002',
  ADDRESS: '0xEaFdE71D84Cbad86323030Ea1799c04103deac25',
  ETHNETWORK: 'mainnet',
  INFURA: '64089c2e1bef4032a89f71267d4dd002',
  TWITTERAPISEC: '6oGos83b8dRWiRfYsHrXy6JDjiR4IBe3RNJuH9xiu3yOMxJcwB',
  TWITTERAPIKEY: 'ceBqvQu185ShJ5bodOsRIpQtd',
  BEARER: 'AAAAAAAAAAAAAAAAAAAAAJD2lAEAAAAASnJJ0q6acP8fgNhNMT9bB6NSksY%3DJnrWf99d5nHdYPgEvzgchpWqiuOqIe9pe7KkSdqqnNCQabKMgg'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
