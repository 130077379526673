// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = (blockchainAccount) => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let totalSupply = await store
        .getState()
        .blockchain.smartContract.methods.symbol()
        .call();
      let cost = await store
        .getState()
        .blockchain.smartContract.methods.PRICE()
        .call();
      // let tokensOwned = await store
      //   .getState()
      //   .blockchain.smartContract.methods.tokensOfOwner(blockchainAccount)
      //   .call();
      dispatch(
        fetchDataSuccess({
          totalSupply,
          cost,
          // tokensOwned,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};

// export const fetchCoinList = (account) => {
//   return async (dispatch) => {
//     dispatch(fetchDataRequest());
//     try {
//       let totalCoinList = await store
//         .getState()
//         .blockchain.smartContract.methods.listTokensForOwner(account)
//         .call();
//       // let cost = await store
//       //   .getState()
//       //   .blockchain.smartContract.methods.cost()
//       //   .call();

//       dispatch(
//         fetchDataSuccess({
//           totalCoinList,
//           // cost,
//         })
//       );
//     } catch (err) {
//       console.log(err);
//       dispatch(fetchDataFailed("Could not load data from contract."));
//     }
//   };
// };
