import { library } from "@fortawesome/fontawesome-svg-core";
import { faLink, faPowerOff, faUser, faCheck, faStar, faAward, faMedal, faMinus, faPlus, faTimes, faUsers, faEdit, faPeopleArrows, faArrowUp, faArrowDown, faWaveSquare, faLock, faCheckDouble, faCheckCircle, faDog, faWallet, faTrash, faCog, faGamepad } from "@fortawesome/free-solid-svg-icons";

function initFontAwesome() {
  library.add(faLink);
  library.add(faUser);
  library.add(faPowerOff);
  library.add(faCheck);
  library.add(faStar);
  library.add(faAward);
  library.add(faMedal);
  library.add(faMinus);
  library.add(faPlus);
  library.add(faUsers);
  library.add(faEdit);
  library.add(faTimes);
  library.add(faPeopleArrows);
  library.add(faArrowUp);
  library.add(faArrowDown);
  library.add(faWaveSquare);
  library.add(faLock);
  library.add(faCheckCircle);
  library.add(faDog);
  library.add(faWallet);
  library.add(faTrash);
  library.add(faCog);
  library.add(faGamepad);
}

export default initFontAwesome;
