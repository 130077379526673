import React, { useEffect, useState } from "react";
// import logo from "../assets/logo.svg";
import { db } from '../firebase';
import { collection, updateDoc, getDoc, doc, getDocs } from "firebase/firestore";
import moment from 'moment';
import { useParams, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth0 } from "@auth0/auth0-react";
import Gravatar from 'react-gravatar';
import { Button } from "reakit/Button";
import bonusRewards from '../bonus-rewards.json';
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import Avatar from '../utils/avatar';
import 'react-toastify/dist/ReactToastify.css';
import {
  useDialogState,
  Dialog,
  DialogBackdrop,
  DialogDisclosure,
} from "reakit/Dialog";

export const LobbyPurchase = () => {

  // const [checkinStatus, setCheckinStatus] = useState(false);
  const history = useHistory();
  const dialogBadge = useDialogState();
  const [currentBadgeInfo, setcurrentBadgeInfo] = useState({});
  const [fullLeaderBoard, setLeaderBoard] = useState([]);
  const [productList, setProductList] = useState([]);
  const [originalList, setOriginalList] = useState([]);
  const [resetList, setResetList] = useState(false);
  const [rewardsList, setRewardsList] = useState([]);
  const [bonusRewardsList, setbonusRewardsList] = useState([]);
  const [currentOrder, setcurrentOrder] = useState([]);
  const [userPurchases, setuserPurchases] = useState([]);
  const [userEarningTotals, setuserEarningTotals] = useState([]);
  const [currentUser, securrentUser] = useState({});
  const dialog = useDialogState();
  const { user } = useAuth0();
  const { userId } = useParams();
  let docRef = {};
  let tempProductList = []

  useEffect(() => {
    const getBonusRewardList = () => {
      let clonedbonusRewards = [];
      getDocs(collection(db, 'bonusRewards'))
      .then((result) => {
        result.forEach((doc) => {
          let item = doc.data();
          item.id = doc.id;
          clonedbonusRewards.push(item);
        });
         // we need to get this id to read from firebase
          setProductList(clonedbonusRewards);
          setOriginalList(clonedbonusRewards);
      });
    }
    
    gettingUserInfoFromFullList(userId);
    getBonusRewardList();
   
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if(resetList) {
      console.log('resting', originalList);
      setProductList(originalList);
      history.push('/lobby');
    }
  }, [resetList]);

  const gettingUserInfoFromFullList = async (userIdst) => {
    console.log('userId', userIdst);
    let tempUserData = {};

    docRef = doc(db, "purchasedTokens", userIdst);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      console.log('yooooo')
      tempUserData = docSnap.data();
      tempUserData.metaData.lastVist = moment(tempUserData.metaData?.checkins[1]).format("MMM Do, YYYY");
      // let userWalletInfo = tempUserData;
      console.log('HAWK tempUserData ', tempUserData.purchases);
      setuserPurchases(tempUserData.purchases);
      setuserEarningTotals(tempUserData.earnedTotals);
      securrentUser(tempUserData);
      setRewardsList(tempUserData.rewards)
      if (tempUserData.bonusrewards) {
        setbonusRewardsList(tempUserData.bonusrewards);
      }
    }
  }; 

  const saveUsersPurchase = async () => {
    docRef = doc(db, "purchasedTokens", userId);
    // dialog.hide();
    const now = new Date();
    let itemList = [];
    let newArray = [];
    let earnedNewPoints = 0;
    console.log('save this dude ', productList);
    console.log('userId ', userId);

    // loop through list and push anthing that doesnt have a 0 qnty to a purchase array
    productList.forEach((prod, i) => {
      if (prod.total >= 1) {
        itemList.push(prod);  // save full transaction
        earnedNewPoints = earnedNewPoints + (prod.pointTotal*prod.total);
        const sendingObj = {
          name: prod.name,
          totalItems: prod.total,
          pointTotals: prod.pointTotal*prod.total,
          pointPer: prod.pointTotal
        }
        newArray.push(sendingObj); // save just the purchase reward info
      }
    });
    console.log('newArray ', newArray);
    if(userPurchases === undefined) {
      let formedPurchases = [{
        itemList: itemList,
        purchaseDate: now
      }]
      await updateDoc(docRef, {
        purchases: formedPurchases,
        earnedTotals: newArray,
        status: 'complete',
        totalPoints: earnedNewPoints+currentUser.totalPoints
      });
      toast.success("SUCCESS: Purchase complete!", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      // go back to lobby
      // setProductList(bonusRewards);
      setResetList(true);
      // history.push('/lobby');

    } else {
      let pastPurchases = JSON.parse(JSON.stringify(userPurchases));
      let pastEanredTotals = JSON.parse(JSON.stringify(userEarningTotals));
      console.log('pastPurchases ', pastPurchases);
      console.log('pastEanredTotals ', pastEanredTotals);
      const settingRecord = {
        itemList: itemList,
        purchaseDate: now
      }

      // if previously earnedtoals donte exist
      if(userEarningTotals === undefined) {
        // we dont have any totalls yet we need to create some
        pastEanredTotals = newArray;
      } else {
        // we have totals we need to add too
        // loop through each previeous earned Total..
        pastEanredTotals.forEach((earned, eindex) => {
          // loop through current owned
          newArray.forEach((newearned, beindex) => {
           // if either of the names match
            if(earned.name === newearned.name) {
              console.log(' we have matches e', earned.pointTotals);
              console.log(' we have matches n', newearned.pointTotals);
              const newEarnedTotalPoints = earned.pointTotals + newearned.pointTotals;
              const newTotales = earned.totalItems + newearned.totalItems;
              console.log('newEarnedTotalPoints ', newEarnedTotalPoints);
              console.log('newTotaling ', newTotales);
                // add the totals together and repush the array to save
              pastEanredTotals[eindex].pointTotals = newEarnedTotalPoints;
              pastEanredTotals[eindex].totalItems = newTotales;
            }
          });
        });

        // now you need to loop threw new array again.. 
        newArray.forEach((rewardItems, newloopindex) => {
          // and check to see if PrevEarned includes it
          if (pastEanredTotals.some(e => e.name === rewardItems.name)) {
            // -- if it includes it ignore
            /* vendors contains the element we're looking for */
          } else {
            console.log('pushy ', rewardItems);
            pastEanredTotals.push(rewardItems);
          }
          // -- if it doesnt include you need to push it too.
         });
        

      }
  
      pastPurchases.push(settingRecord);
      setuserPurchases(pastPurchases);
      setuserEarningTotals(pastEanredTotals);
      console.log('pastEanredTotals', pastEanredTotals)
      await updateDoc(docRef, {
        purchases: pastPurchases,
        status: 'complete',
        earnedTotals: pastEanredTotals,
        totalPoints: earnedNewPoints+currentUser.totalPoints
      });
      setResetList(true);
      // setProductList(bonusRewards);
      
    }
  }

  const cancelSave = () => {
    dialog.hide();
  }

  const updateRewards = async (currentBadge) => {

    docRef = doc(db, "purchasedTokens", userId); // grabbing users profile in DB
    let tempCurrentBadge = currentBadge;
    let tempUserRewardlist = rewardsList;

    console.log('tempCurrentBadge ', tempCurrentBadge);
    console.log('tempUserRewardlist ', tempUserRewardlist);

    // check to see if its a regular rewards
    if(currentBadge.rid !== undefined) {

      tempUserRewardlist.forEach((item, index) => {
        if(currentBadge.rid === item.rid) {
          console.log('we are settring rtreu')
          tempUserRewardlist[index].claimed = true;
          tempCurrentBadge.claimed = true;
        }
        setRewardsList(tempUserRewardlist);
      });
      console.log('tempUserRewardlist 2 ', tempUserRewardlist)
      setcurrentBadgeInfo(tempCurrentBadge);
      await updateDoc(docRef, {
        rewards: tempUserRewardlist
      });

    }
    
    dialogBadge.hide();
  }
  
  const decrementMintAmount = (total, i) => {
    const clone = productList.slice(0);
    let newTotal = total - 1;
    if(newTotal <= 0) {
      clone[i].total = 0;
    } else {
      clone[i].total = newTotal;
    }
    setProductList(clone);
  };

  const incrementMintAmount = (total, i) => {
    const clone = productList.slice(0);
    clone[i].total = total + 1;
    setProductList(clone);
  };
  
  const badgeDetails = (badge) => {
    console.log('badge ', badge);
    setcurrentBadgeInfo(badge);
  } 


  return (
    <div className="text-center hero my-5 leadery lgwide">
    {/* <img className="mb-3 app-logo" src={logo} alt="React logo" width="120" /> */}
    <h1 className="mb-4 four">{ currentUser?.userName ? currentUser.userName : currentUser?.metaData?.nickname}</h1>

    <div className="leader-contain chng">
        <div className="purchase-container">
        <Avatar minus={true} address={currentUser.emailAddress} avatar={currentUser.avatar ? currentUser.avatar : undefined}/>
          <div className="userinfo">
            <span className="pointse">{ currentUser?.totalPoints } points</span>
            <span className="lastvis">Last Visit { currentUser?.metaData?.lastVist }</span>
            <span className="lastvis">User Level { currentUser?.level }</span>
          </div>
          <div className="notes">
          <div className="badges">
            {currentUser?.rewards?.map((d, i) => (
              !d.claimed && d.type === 'physical' && 
                <div className="badge" key={i}>
                  <DialogDisclosure className="open-dia" {...dialogBadge}  onClick={() => badgeDetails(d)}>
                    <div className={'leader-rank newbadge ' + d.image}></div>
                  </DialogDisclosure>
                </div>
            ))}
            </div>
          </div>
        </div>

    </div>
    <div className="porductinfod">
      {productList?.map((j, i) => (
        <div key={i} className="product-i shmi">
          <h4 className="prodname">
            {j.name}
            <span className="totals">{j.total}</span>  
          </h4>
          <div className="card-button-holders">
            <Button className="inc down" onClick={() => {decrementMintAmount(j.total, i);}}>
              <FontAwesomeIcon icon="minus" className="mr-2 hawk" />
            </Button>
            <Button className="inc up"  onClick={(e) => {incrementMintAmount(j.total, i);}}>
              <FontAwesomeIcon icon="plus" className="mr-2 hawk" />
            </Button>
          </div>
          
        </div>
      ))}
    </div>
          {/* <div className="orderfill">
            <strong>Total Order Info</strong>
            {currentOrder?.map((k, i) => (
              <div key={i} className="product-i">
                <h4 className="prodname">{k.name}</h4>
                <h4 className="prodname">{k.qty}</h4>
              </div>
            ))}
          </div> */}
    <div className="button-holders">
      <Link to={'/lobby'} className="container-lead open-dia diachange">
        <Button className="clicklink linker checkouts grrre" onClick={() => cancelSave()}>Cancel</Button>
      </Link>
      <Button className="clicklink linker checkouts" onClick={() => saveUsersPurchase()}>Checkout</Button>
    </div> 
    <DialogBackdrop {...dialogBadge}  className="backstyle">
            <Dialog className="refix" {...dialogBadge} aria-label="Welcome">
              <h3 className="claimh claimg">Badge Details</h3>
              <Button className="closebtn" onClick={dialogBadge.hide}><FontAwesomeIcon icon="times" className="mr-2" /></Button>
              <div className="flutarsky">
               <div className="childtarsky">
                  <div className="imgholdy">
                    <div className={'leader-rank newbadge newbig ' + currentBadgeInfo.image}></div>
                  </div>

                  <div className="holdertarsky">
                    <span className="lab">Reward Name:</span>
                    {currentBadgeInfo.name}
                  </div>                 
                
                  <div className="holdertarsky">
                    <span className="lab">Reward Earned:</span>
                    {currentBadgeInfo.reward}
                  </div>
                  {currentBadgeInfo.claimed &&
                     <div className="status-rew claimed">CLAIMED</div>
                  }
                  {/* */}
                  {/* <div className="status-rew expired">EXPIRED</div> */}
                  {!currentBadgeInfo.claimed &&
                    <div className="status-rew activer clicks" onClick={() => updateRewards(currentBadgeInfo)}>USE REWARD</div>
                  }
               </div>
              </div>
            
            </Dialog>
            </DialogBackdrop>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              />
              {/* Same as */}
            <ToastContainer />
  </div>
  
  );
};


export default LobbyPurchase;