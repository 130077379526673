import React, { useEffect, useState } from "react";
import Qrcodes from "../check-code.json";
import moment from 'moment';
import {QRCodeSVG} from 'qrcode.react';
import logo from "../assets/LPM.png";


export const Qr = () => {
  const [isOpen, setIsOpen] = useState(false);
  // const currentMonth = moment(new Date()).format('MMMM');
  const currentDay = moment().day();
  const [imageString, setimageString] = useState('');
//   const [currentQrInfo, setcurrentQrInfo] = useState({});
const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    gettingCorrectQr();
    return () => {};
  }, []);
  

  const gettingCorrectQr = async () => {
      // loop through QR json and see whicch one matches the date
      Qrcodes.forEach((item) => {
        // item.formedDate = new Date
        if(item.date === currentDay) {
        //   setcurrentQrInfo(item);
          switch (item.date) {
              case 0:
              setimageString(item.value);
              break;
              case 1:
              setimageString(item.value);
              break;
              case 2:
              setimageString(item.value);
              break;
              case 3:
              setimageString(item.value);
              break;
              case 4:
              setimageString(item.value);
              break;
              case 5:
              setimageString(item.value);
              break;
              case 6:
              setimageString(item.value);
              break;
              default:
              setimageString(item.value);
              break;
            };
        };
      });
  };

  return (
    <div className="text-center hero my-5">
      <div className="scanlogoholder" style={{alignItems: "center"}}>
      <img alt="logo" className="mb-3 app-logo updates" src={logo} width="220" style={{paddingTop: "26px"}} />
      <span className="bblogo">Lucy Pack Magic</span>
      <span className="sublogo">Rewards Program</span>
      </div>
      
      
       <a className="qrlink" href={'https://rewards.lucypackmagic.com/check-link' + imageString}>
       {/* <a className="qrlink" href={'https://hulmeville-inn-rewards.web.app/check-link' + imageString}> */}
        <div id="mountNode" className="qr-image" >
          <QRCodeSVG size="380" includeMargin="true" fgColor="#52376f" bgColor="#ffffff" value={'https://rewards.lucypackmagic.com/check-link' + imageString} />
          {/* <QRCodeSVG size="380" includeMargin="true" fgColor="#d4cac8" bgColor="#111" value={'https://hulmeville-inn-rewards.web.app/check-link' + imageString} /> */}
          {/* <div className="scanclick"><FontAwesomeIcon icon="arrow-up" className="editbut" />Scan me!</div> */}
          
          {/* <div id="minterbtn">
            <NFDButton label="Login" icon="lock" sublabel="Check Your Rewards" color="blue-btn" />
          </div> */}
        </div>
        </a>
        {/* <img src={NFDBanner} alt="DudesGood" className={`nfdbanner`} style={{height: '60px'}}/> */}
    </div>
    
  );
};

export default Qr;